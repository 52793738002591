import { useParams } from "react-router-dom";
import Spinner from "../components/baseList/Spinner";
import ForumDetails from "../components/baseEditor/editor/forum/ForumDetails";
import Breadcrumbs from "../components/baseEditor/common/Breadcrumbs";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import useIsAuthenticated from "../hooks/useIsAuthenticated";
import TabLayout from "../components/layout/TabLayout";

export default function ForumEditor() {
  const { baseId, forumId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  useIsAuthenticated();

  return (
    <div className="w-full h-screen p-2">
      <Breadcrumbs />
      {user && <>
        {!(baseId && forumId) && <Spinner width="w-8" height="h-8" border="border-2" />}
        {baseId && forumId && <TabLayout baseId={baseId} firstLabel="Forum">
          <ForumDetails baseId={baseId} forumId={forumId} />
        </TabLayout>}
      </>}
    </div>
  );
}

import { Dialog, Switch } from '@headlessui/react'
import { useContext, useState } from 'react';
import { AuthContext, AuthContextType } from '../../context/AuthContext';
import Spinner from './Spinner';

type AddCrewDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  removeBase: (id: string) => void;
  editingBase: string;
  setEditingBase: (id: string) => void;
}

export default function DeleteConfirmDialog({ isOpen, setIsOpen, removeBase, editingBase, setEditingBase }: AddCrewDialogProps) {

  const { user } = useContext(AuthContext) as AuthContextType;
  const [cancelSubscription, setCancelSubscription] = useState<boolean>(false);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setEditingBase("");
      }}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto w-full lg:w-1/2 p-4 rounded bg-white">
          <Dialog.Title className="flex items-center">
            <span className="text-lg">Delete Base?</span>
          </Dialog.Title>

          <div className="flex flex-col border-t mt-2 pt-2">
            <p className="w-full">Are you sure you want to delete this Base?</p>

            <div className="flex flex-wrap mt-2">
              <div className="w-full md:w-1/2 p-2">
                <button
                  onClick={() => {
                    removeBase(editingBase);
                    setIsOpen(false);
                  }}
                  className="w-full p-2 border bg-teal-500 text-white rounded-md"
                >
                  Yes
                </button>
              </div>
              <div className="w-full md:w-1/2 p-2">
                <button
                  onClick={() => {
                    setEditingBase("");
                    setIsOpen(false);
                  }}
                  className="w-full p-2 border border-teal-500 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import Spinner from "../../../baseList/Spinner";
import { BaseMemberType } from "../../../../common/types";
import BaseMember, { BASE_MEMBER_ROLE_ADMIN, BASE_MEMBER_ROLE_RESOURCE } from "./BaseMember";
import BaseMemberAddForm from "./BaseMemberAddForm";
import BaseMemberEditForm from "./BaseMemberEditForm";
import useBaseMemberApi from "../../common/hooks/useBaseMemberApi";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useMediaQuery } from "usehooks-ts";
import TeamsPanel from "./teamsPanel/TeamsPanel";
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent } from "@dnd-kit/core";

type BaseMembersPropsType = {
  baseId: string;
}

export default function BaseMemberList({ baseId }: BaseMembersPropsType) {
  const { user } = useContext(AuthContext) as AuthContextType;

  const [editingMember, setEditingMember] = useState<BaseMemberType | null>(null);

  const [showTeamsPanel, setShowTeamsPanel] = useState(false);
  const matches = useMediaQuery('(min-width: 640px)');

  const {
    baseMembersQuery,
    addBaseMember,
    removeBaseMember,
    editBaseMember,
  } = useBaseMemberApi({ baseId, user });


  const [adminMembers, setAdminMembers] = useState<BaseMemberType[]>([]);
  const [resourceMembers, setResourceMembers] = useState<BaseMemberType[]>([]);

  useEffect(() => {
    if (baseMembersQuery.data?.length) {
      const _adminMembers = baseMembersQuery.data.filter(
        (member: BaseMemberType) => member.role === BASE_MEMBER_ROLE_ADMIN)
      setAdminMembers(_adminMembers);

      const _resourceMembers = baseMembersQuery.data.filter(
        (member: BaseMemberType) => member.role === BASE_MEMBER_ROLE_RESOURCE)
      setResourceMembers(_resourceMembers);
    }
  }, [baseMembersQuery.data]);

  const [draggingItem, setDraggingItem] = useState<BaseMemberType | null>(null);

  const handleDragEnd = (e: DragEndEvent) => {
    if (e.over?.data.current && e.active.data.current) {
      const addTeamMember = e.over.data.current["addTeamMember"];
      const baseMember: BaseMemberType = e.active.data.current["baseMember"];

      setDraggingItem(null)

      const teamMember = {
        baseMemberId: e.active.id,
        name: baseMember.name,
        email: baseMember.email,
        timeCommitment: 0,
        participationLevel: 0,
        unfixRoles: [],
      };

      addTeamMember(teamMember);
    }
  }

  const handleDragStart = (e: DragStartEvent) => {
    if (e.active.data.current) {
      const baseMember: BaseMemberType = e.active.data.current["baseMember"];
      setDraggingItem(baseMember);
    }
  }

  return (
    <div id="base_members" className="w-full">

      <div className="py-2 md:px-2 w-full">
        <div className="border rounded-md p-2">
          <div className="flex mb-2">
            {(baseMembersQuery.isFetching) &&
              <Spinner width="w-8" height="h-8" border="border-2" />}
            <h2 className="text-xl">Members</h2>
          </div>

          <div className="flex flex-nowrap">
            <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
              <div className={`${matches && showTeamsPanel ? "w-1/2" : "w-full"} min-h-[12rem]`}>
                {baseMembersQuery.data?.length === 0 && (
                  <ul className="my-2">
                    <li className="text-slate-500">No items...</li>
                  </ul>
                )}

                {adminMembers.length > 0 && (
                  <>
                    <div className="w-full p-1 font-medium">Admins</div>
                    <div className="flex flex-wrap">
                      {adminMembers.map((baseMember: BaseMemberType) => (
                        <BaseMember
                          key={baseMember.id}
                          baseMember={baseMember}
                          deleteItem={removeBaseMember}
                          setEditingMember={setEditingMember}
                          isTeamsPanelOpen={showTeamsPanel}
                        />
                      ))}
                    </div>
                  </>
                )}

                {resourceMembers.length > 0 && (
                  <>
                    <div className="w-full p-1 font-medium">Resources</div>
                    <div className="flex flex-wrap">
                      {resourceMembers.map((baseMember: BaseMemberType) => (
                        <BaseMember
                          key={baseMember.id}
                          baseMember={baseMember}
                          deleteItem={removeBaseMember}
                          setEditingMember={setEditingMember}
                          isTeamsPanelOpen={showTeamsPanel}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div onClick={() => { setShowTeamsPanel(!showTeamsPanel); }} className="hidden sm:block flex flex-col items-center border-l border-teal-500 w-8">
                {showTeamsPanel && <ChevronRightIcon className="w-6 h-6 mt-4 ml-1" />}
                {!showTeamsPanel && <ChevronLeftIcon className="w-6 h-6 mt-4 ml-1" />}
                {/* <div className="flex items-center"> */}
                <div className="whitespace-nowrap rotate-90 mt-4">Crews & Forums</div>
                {/* </div> */}
              </div>
              {showTeamsPanel && <TeamsPanel baseId={baseId} />}

              <DragOverlay>
                {draggingItem &&
                  <BaseMember
                    key={draggingItem.id}
                    baseMember={draggingItem}
                    deleteItem={removeBaseMember}
                    setEditingMember={setEditingMember}
                    isTeamsPanelOpen={showTeamsPanel}
                  />}
              </DragOverlay>
            </DndContext>
          </div>
          {editingMember && <BaseMemberEditForm baseId={baseId} editItem={editBaseMember} setEditingMember={setEditingMember} baseMember={editingMember} />}
          {!editingMember && <BaseMemberAddForm baseId={baseId} addItem={addBaseMember} />}
        </div>
      </div>

    </div>
  );
}

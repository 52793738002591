import { useParams } from "react-router-dom";
import Spinner from "../components/baseList/Spinner";
import TurfDetails from "../components/baseEditor/editor/turf/TurfDetails";
import Breadcrumbs from "../components/baseEditor/common/Breadcrumbs";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import useIsAuthenticated from "../hooks/useIsAuthenticated";
import TabLayout from "../components/layout/TabLayout";

export default function TurfEditor() {
  const { baseId, turfId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  useIsAuthenticated();

  return (
    <div className="w-full h-screen p-2">
      <Breadcrumbs />
      {user && <>
        {!(baseId && turfId) && <Spinner width="w-8" height="h-8" border="border-2" />}
        {baseId && turfId && <TabLayout baseId={baseId} firstLabel="Turf">
          <TurfDetails baseId={baseId} turfId={turfId} />
        </TabLayout>}
      </>}
    </div>
  );
}

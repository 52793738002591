import CrewList from "./crew/CrewList";
import ForumList from "./forum/ForumList";
import TurfList from "./turf/TurfList";
import ValueStreamList from "./valueStream/ValueStreamList";
import Base from "./base/Base";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TrialFlash from "../common/TrialFlash";
import useBaseApi from "../common/hooks/useBaseApi";

type EditorPropsType = {
  baseId: string;
}

export default function Editor({ baseId }: EditorPropsType) {


  return (
    <div id="editor" className="w-full">

      <div className="py-2 md:px-2 w-full">
        <div className="border rounded-md p-2">

          <Base baseId={baseId} />
        </div>
        </div>
        <div className="flex flex-wrap">
          <CrewList baseId={baseId} />
          <ForumList baseId={baseId} />
          <TurfList baseId={baseId} />
          <ValueStreamList baseId={baseId} />
        </div>
    </div>
  );
}
